export const mymixins = {
    data() {
        return {
            count: 2020
        }
    },
    computed: {},
    watch: {},

    methods: {
        ranFun(min, max) {
            return Math.floor(Math.random() * (max - min + 1) + min);
        }
    },
    mounted() {

    }
}

