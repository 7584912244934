<template>
  <div class="notfound" ref="bgstar">
      <img src="../../assets/img/404_for.png" class="img404 animated pulse infinite" alt />
    <span
      v-for="(item,i) in spanNum"
      :style="{top:item.y,left:item.x,transform:'scale('+item.scaler+')',animationDelay:item.rate,backgroundColor:item.rgb}"
      :key="i"
      class="span-dots"
    ></span>
  </div>
</template>

<script>
import { mymixins } from "@/utils";
export default {
  mixins: [mymixins],
  data() {
    return {
      spanNum: [],
    };
  },
  // methods:{
  //   ranFun(min, max){
  //       return Math.floor(Math.random() * (max - min + 1) + min);
  //   }
  // },
  mounted() {
    var screenW = this.$refs.bgstar.offsetWidth;
    var screenH = this.$refs.bgstar.offsetHeight;
    for (var i = 0; i < 800; i++) {
      //位置随机
      var x = Math.floor(Math.random() * screenW);
      var y = Math.floor(Math.random() * screenH);
      //大小随机
      var scaler = Math.random() * 1.8;
      //频率随机
      var rate = Math.random() * 2 + 0.5;
      var r = this.ranFun(190, 255);
      var g = this.ranFun(180, 255);
      var b = this.ranFun(0, 160);
      this.spanNum.push({
        x: x + "px",
        y: y + "px",
        scaler: scaler,
        rate: rate + "s",
        rgb: `rgb(${r},${g},${b})`,
      });
    }
  },
};


</script>

<style lang="scss" scoped>


.notfound {
  width: 100%;
  height: 100%;
  background: #0b0f15;
  overflow: hidden;
  position: relative;
}

.span-dots {
  position: absolute;
  animation: flash 2s alternate infinite;
  width: 3.5px;
  height: 3.5px;
  border-radius: 50%;
  // background: #fff;
}
@keyframes flash {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

.img404 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
</style>